import React, { FC } from 'react'
import { HashRouter } from 'react-router-dom'
import './App.css'
import { RoutesConfig } from './routes/routes'
import { observer } from 'mobx-react'
import { useMemo } from 'react'
import { RootStore } from '@/stores/root-store'
import { RootContext } from '@/contexts/RootContext'
import { ConfigProvider } from 'antd'
import { useZoom } from '@/hooks/useZoom'
import Theme from './theme/index'
import zhCN from 'antd/lib/locale/zh_CN' // 根据你的antd版本，路径可能有所不同
import dayjs from 'dayjs'

import 'dayjs/locale/zh-cn'

dayjs.locale('zh-cn')
const App: React.FC = observer(() => {
  useZoom()
  const rootStore = useMemo(() => new RootStore(), [])
  // 暂时只有一种主题
  const customTheme = Theme
  return (
    <RootContext.Provider value={rootStore}>
      <ConfigProvider 
        theme={customTheme}
        locale={zhCN}>

        <div className='App'>
          <AppContent />
        </div>
      </ConfigProvider>
    </RootContext.Provider>
    
  )
})


const AppContent: FC = observer(() => {
  return (
    <HashRouter>
      <RoutesConfig />
    </HashRouter>
  )
})
export default App
