import { makeAutoObservable } from 'mobx'
import { RootStore } from '../root-store'
import { QueryUserRespond } from '@/api/user/type'
import { leftMenuItems, CustomBreadcrumbProps, MenuItems, IAlarmRules } from './type'
export class PublicStore {
  rootStore: RootStore
  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }
  public currentMenuPath: string = '/dashbord/data-board'  // 左侧菜单当前路径
  public currentMenuItem: string[] = [] // 面包屑
  public customBreadcrumb:CustomBreadcrumbProps = {
    showAddDevice: false, // 是否展示新增设备面包屑
    showEditDevice: false, // 是否展示编辑设备面包屑
    showDeviceInfo: false,
    showAddTask: false, // 是否展示新增任务面包屑
    showEditTask: false, // 是否展示编辑任务面包屑
    showModifyConfig: false, // 是否展示配置修改面包屑
    showModifyHistory: false, // 是否展示修改历史面包屑
    showAccountUser: false, // 是否展示新增账号面包屑
    showEditAccountUser: false,
    showFirmwarelibrary:false,// 是否展示包资源库面包屑
    showEditFirmwarelibrary: false,
    showVehicleModel :false, //是否展示车型管理
    showVehicle :false, //是否展示车辆管理
    showEditVehicle :false,
    showRole:false,//是否展示新增权限
    showEditRole:false,
    showRoleDetail:false, 
    showTester:false, //是否新增测试员
    showEditTester:false,
    showDatabase: false, //是否新增数据库
    showEditDatabase: false,
    showCustomerTags: false, //是否新增标签
    showEditCustomerTags: false,
    showAddTestProject: false,
    showEditTestProject: false,
    showTestProjectDetail: false,
    showFileDetail: false,
    showAddAnnounce: false,
    showEditAnnounce: false,
  }
  public alarmsRule: IAlarmRules = {}
  public userInfo: QueryUserRespond = null
  public userAuthCodes: string[] = []
  public setCurrentMenuPath(value: string) {
    if(this.currentMenuPath !== value){
      // 初始化面包屑状态
      for(let i in this.customBreadcrumb){
        this.customBreadcrumb[i] = false
      }
    }
    // 当是实时监控时，应将左侧菜单key值设置为对应的监控path
    if(value.includes('/monitoring/single-device-monitoring')){
      this.currentMenuPath = '/monitoring/single-device-monitoring'
    }else{
      this.currentMenuPath = value;
    }
    
    if(value){
      // 使用include是由于单设备监控路径可能包含device_id
      const filterLeftMenu = leftMenuItems.find((item: MenuItems) => value.includes(item.menuPath))
      if(filterLeftMenu){
        this.setCurrentMenuItem(filterLeftMenu.breadCrumb)
      }
    }
  }

  public getCurrentMenuPath(): string {
    return this.currentMenuPath;
  }
  // 修改为一个方法
  public setCurrentMenuItem(value: string[]) {
    this.currentMenuItem = value;
  }
  public setTmpCurrentMenuPath(value: string) {
    this.currentMenuPath = value;
  }
  public setUserAuthCodes(value: string[]): void {
    this.userAuthCodes = value
  }
  public getUserAuthCodes(): string[] {
    return this.userAuthCodes
  }

  public getCurrentMenuItem(): string[] {
    return this.currentMenuItem;
  }
  // 方法集合 修改面包屑
  public setShowBreadcrumb(key:keyof CustomBreadcrumbProps,value:boolean){
    this.customBreadcrumb[key] = value
  }
  public getShowBreadcrumb(key:keyof CustomBreadcrumbProps):boolean{
    return this.customBreadcrumb[key]
  }
  public getTableHeight(searchHeight:number){
    // 头部 + 面包屑 229px
    // searchHeight 表格搜索框+表格header的高度
    
    let pageHeight = document.body.clientHeight
    // console.log((pageHeight - searchHeight - 229) + 'px')
    return (pageHeight - searchHeight - 229) + 'px'
  }
  public getAlarmsRules(): IAlarmRules {
    return this.alarmsRule;
  }
  public setAlarmsRules(value: IAlarmRules) {
    this.alarmsRule = value;
  }
  public setUserInfo (value: QueryUserRespond): void {
    this.userInfo = value
    
  }
  public getUserInfo (): QueryUserRespond {
    return this.userInfo
  }
}
