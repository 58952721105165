import { makeAutoObservable } from 'mobx'
import { RootStore } from '../root-store'
import { QueryUserRespond } from '@/api/user/type'
export class UserStore {
  rootStore: RootStore
  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }
  public userInfo: QueryUserRespond = null
  public setUserInfo (value: QueryUserRespond): void {
    this.userInfo = value
    
  }
  public getUserInfo (): QueryUserRespond {
    return this.userInfo
  }

}
