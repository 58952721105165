import { makeAutoObservable } from 'mobx'
import { UserStore } from './user/user-store'
import { PublicStore } from './public/public'
import { DeviceStore } from './device/device-store'
export class RootStore {
  userStore: UserStore
  publicStore: PublicStore
  deviceStore: DeviceStore
  constructor () {
    this.userStore = new UserStore(this)
    this.publicStore = new PublicStore(this)
    this.deviceStore = new DeviceStore(this)
    makeAutoObservable(this)
  }
}
