import { useState, useEffect } from 'react'

export function useZoom (): void {
  const [zoom, setZoom] = useState('1')

  useEffect(() => {
    const handleResize = (): void => {
      const newZoom = (window.screen.width / 1920).toFixed(2)
      if (newZoom !== zoom) {
        setZoom(newZoom)
        const bodyStyle = document.body.style as any
        // bodyStyle.zoom = Number(newZoom) // 直接修改元素的zoom，会导致video进度条拖动时定位有误
        bodyStyle.transform = `scale(${newZoom})`
        bodyStyle.transformOrigin = 'top left' // 设置缩放的原点为左上角
        bodyStyle.width = `${100 / Number(newZoom)}%` // 让 body 铺满视口
        bodyStyle.height = `${100 / Number(newZoom)}%` // 让 body 铺满视口
        bodyStyle.overflow = 'hidden' // 隐藏超出部分
      }
    }
    handleResize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom]) // 将 zoom 作为依赖项，以便在 zoom 变化时重新运行 effect
}
