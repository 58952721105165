import { getRoutes } from '../utils/route'


import { FC } from 'react'
import { useRoutes } from 'react-router-dom'
import { isLoginRoutes, notLoginRoutes } from './config'


export const RoutesConfig: FC = () =>{
  const jwtToken = localStorage.getItem('jwtToken') || ''
  let routeItems = []
  if (!jwtToken){
    routeItems = getRoutes(notLoginRoutes)
  } else {
    routeItems = getRoutes(isLoginRoutes)
   
  }
  // routeItems = getRoutes(isLoginRoutes) // test
  
  return useRoutes(routeItems)
}
