import { Navigate } from 'react-router-dom'
import { lazy } from 'react'
import { routesWithMenuItem } from './types'
const Login = lazy(() => import('../views/Login/Login'))
const Layout = lazy(() => import('../views/Layout/Layout'))
const Dashbord = lazy(() => import('../views/Dashbord/Dashbord'))
const DeviceManagement = lazy(() => import('../views/DeviceManagement/DeviceManagement'))
const DeviceList = lazy(() => import('../views/DeviceManagement/DeviceList/DeviceList'))
const DeviceConfiguration = lazy(() => import('../views/DeviceManagement/DeviceConfiguration/DeviceConfiguration'))
const DeviceFirmwareUpdate = lazy(() => import('../views/DeviceManagement/DeviceFirmwareUpdate/DeviceFirmwareUpdate'))
const Monitoring = lazy(() => import('../views/Monitoring/Monitoring'))
const GlobalMoitoring = lazy(() => import('../views/Monitoring/GlobalMoitoring'))
const SignalDeviceMonitoring = lazy(() => import('../views/Monitoring/SignalDeviceMonitoring'))
const TestProjectManagement = lazy(() => import('../views/TestProjectManagement/TestProjectManagement'))
const TestProject = lazy(() => import('../views/TestProjectManagement/TestProject/TestProject'))
const TestingDataAnalysis = lazy(() => import('../views/TestingDataAnalysis/TestingDataAnalysis'))
const TestingCheck = lazy(() => import('../views/TestingDataAnalysis/TestingCheck/TestingCheck'))
const TestingShow = lazy(() => import('../views/TestingDataAnalysis/TestingShow/TestingShow'))
const FirmwareLibrary = lazy(() => import('../views/FirmwareLibrary/FirmwareLibrary'))
const FirmwareLibraryList = lazy(() => import('../views/FirmwareLibrary/FirmwareLibrary/FirmwareLibraryList'))
const DatabaseManagement = lazy(() => import('../views/DatabaseManagement/DatabaseManagement'))
const Database = lazy(() => import('../views/DatabaseManagement/Database/Database'))
const MasterDataManagement = lazy(() => import('../views/MasterDataManagement/MasterDataManagement'))
const VehicleModel = lazy(() => import('../views/MasterDataManagement/VehicleModel/VehicleModel'))
const Vehicle = lazy(() => import('../views/MasterDataManagement/Vehicle/Vehicle'))
const Tester = lazy(() => import('../views/MasterDataManagement/Tester/Tester'))
const SystemManagement = lazy(() => import('../views/SystemManagement/SystemManagement'))
const AccountManagement = lazy(() => import('../views/SystemManagement/AccountManagement/AccountManagement'))
const RoleManagement = lazy(() => import('../views/SystemManagement/RoleManagement/RoleManagement'))
const Download = lazy(() => import('../views/Download/Download'))
const DownloadList = lazy(() => import('../views/Download/DownloadList/DownloadList'))

const EditPassword = lazy(() => import('../views/EditPassword/EditPassword'))
const Password = lazy(() => import('../views/EditPassword/Password/Password'))

const CustomerTags = lazy(() => import('../views/SystemManagement/CustomerTags/CustomerTags'))
const OperationLog = lazy(() => import('../views/SystemManagement/OperationLog/OperationLog'))
const NotFoundPage = lazy(() => import('@/components/NotFoundPage/NotFoundPage'))
const Announcements = lazy(() => import('@/views/SystemManagement/Announcements/Announcements'))
const Volcengine = lazy(() => import('@/views/Volcengine/Volcengine'))

export const isLoginRoutes: routesWithMenuItem[] = [
  {
    path: '/',
    element: <Navigate to='/login' />,
  },
  {
    path: '/login',
    element: <Navigate to='/device-management/device-list' />,
  },
  {
    path: '/volcengine',
    element: <Volcengine />,
  },
  {
    path: '/dashbord',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Layout />,
        children: [
          {
            path: '',
            element: <Navigate to='/dashbord/data-board' />,
          },
          {
            path: 'data-board',
            element: <Dashbord />,
          },
        ]
      }
    ]
  },
  {
    path: '/device-management',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <DeviceManagement />,
        children: [
          {
            path: '',
            element: <Navigate to='/device-management/device-list' />,
          },
          {
            path: 'device-list',
            element: <DeviceList />,
          },
          {
            path: 'device-configuration',
            element: <DeviceConfiguration />,
          },
          {
            path: 'device-firmware-update',
            element: <DeviceFirmwareUpdate />,
          },
        ], 
      }
    ]
  },
  {
    path: '/test-project-management',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <TestProjectManagement />, 
        children: [
          {
            path: '',
            element: <Navigate to='/test-project-management/testproject' />,
          },
          {
            path: '/test-project-management/testproject',
            element: <TestProject />, 
          },
        ]
      }
    ]
  },
  {
    path: '/monitoring',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Monitoring />,
        children: [
          {
            path: '',
            element: <Navigate to='/monitoring/global-monitoring' />,
          },
          {
            path: 'global-monitoring',
            element: <GlobalMoitoring />,
          },
          {
            path: 'single-device-monitoring',
            element: <SignalDeviceMonitoring />,
          },
          {
            path: 'single-device-monitoring/:id',
            element: <SignalDeviceMonitoring />,
          },
        ], 
      }
    ]
  },
  {
    path: '/testing-data-analysis',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <TestingDataAnalysis />,
        children: [
          {
            path: '',
            element: <Navigate to='/testing-data-analysis/testing-check' />,
          },
          {
            path: '/testing-data-analysis/testing-check',
            element: <TestingCheck />, 
          },
          {
            path: '/testing-data-analysis/testing-show',
            element: <TestingShow />, 
          }
        ]
      }
      
    ]
  },
  {
    path: '/firmware-library',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <FirmwareLibrary />,
        children: [
          {
            path: '',
            element: <Navigate to='/firmware-library/firmware-library-list' />,
          },
          {
            path: '/firmware-library/firmware-library-list',
            element: <FirmwareLibraryList />, 
          },
        ]
      },
    ]
  },
  {
    path: '/database-management',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <DatabaseManagement />,
        children: [
          {
            path: '',
            element: <Navigate to='/database-management/database' />,
          },
          {
            path: '/database-management/database',
            element: <Database />, 
          },
        ]
      },
    ]
  },
  {
    path: '/master-data-management',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <MasterDataManagement />, 
        children: [
          {
            path: '',
            element: <Navigate to='/master-data-management/vehicle-model' />,
          },
          {
            path: '/master-data-management/vehicle-model',
            element: <VehicleModel />, 
          },
          {
            path: '/master-data-management/vehicle',
            element: <Vehicle />, 
          },
          {
            path: '/master-data-management/tester',
            element: <Tester />, 
          }
        ]
      }
    ]
  },
  {
    path: '/system-management',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <SystemManagement />,
        children: [
          {
            path: '',
            element: <Navigate to='/system-management/account-management' />,
          },
          {
            path: '/system-management/account-management',
            element: <AccountManagement />,
          },
          {
            path: '/system-management/role-management',
            element: <RoleManagement />,
          },
          {
            path: '/system-management/operation-log',
            element: <OperationLog />,
          },
          {
            path: '/system-management/customer-tags',
            element: <CustomerTags />,
          },
          {
            path: '/system-management/announcements',
            element: <Announcements />,
          },
        ], 
      }
    ]
  },
  {
    path: '/download',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Download />,
        children: [
          {
            path: '',
            element: <DownloadList />,
          }
        ], 
      }
    ]
  },
  {
    path: '/edit-password',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <EditPassword />,
        children: [
          {
            path: '',
            element: <Password />,
          }
        ], 
      }
    ]
  },
  {
    path: '*',
    element: <NotFoundPage />,
  }
]


export const notLoginRoutes: routesWithMenuItem[] = [
  {
    path: '/',
    element: <Navigate to='/login' />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/volcengine',
    element: <Volcengine />,
  },
  {
    path: '*',
    element: <Navigate to='/login' />,
  }
]


