import { routesWithMenuItem, routesItem } from '../routes/types'
import { Suspense } from 'react'

export function getRoutes (routes: routesWithMenuItem[]): routesItem[] {
  const resultArr: routesItem[] = []
  routes.forEach((item) => {
    item.path !== undefined && resultArr.push({
      path: item.path,
      element: <Suspense fallback={null}>{item.element}</Suspense>,
      ...(item.children ? { children: getRoutes(item.children) } : {})
    })
  })
  return resultArr
}
