// https://ant.design/theme-editor-cn/ 主题配置地址
export default {
  token: {
    borderRadius: 4,
    fontFamily: 'Source Han Sans CN',
  },
  // 修改单独组件如下示例
  components: {
    Button: {
      colorPrimary: '#1664ff',
      colorPrimaryHover: '#2f75ff',
      colorPrimaryActive: '#ff4035',
      'colorError': '#FF4035',
      'colorErrorActive': '#FF4035',
      'colorErrorHover': '#FF4035',
      primaryShadow: 'none',
      colorBgContainerDisabled: 'f5f6f8',
      borderColorDisabled: '#E5E5E5',
      colorTextDisabled: '#cccccc',
      defaultBorderColor: '#E5E5E5',
      defaultColor: '#1664ff',
      defaultHoverBorderColor: '#999999',
      defaultActiveBorderColor: '#ff4035',
      paddingInline: 12,
      paddingBlock: 5,
      borderRadius: 4,
    },
    Checkbox: {
      colorPrimary: '#E01D09',
      colorPrimaryHover: '#E01D09',
    },
    Tabs: {
      inkBarColor: '#1664ff',
      lineWidth: 0
    },
    Steps: {
      colorPrimary: '#E01D09',
      finishIconBorderColor: '#E01D09'
    },
    DatePicker: {
      colorPrimary: '#E01D09',
      cellActiveWithRangeBg: '#f5f6f8',
      hoverBorderColor: '#999999',
      activeBorderColor: '#999999',
      colorIconHover: '#999999',
      activeShadow: '0 0 0 0.5px #999999',
      cellBgDisabled: '#fff',
    },
    Select: {
      optionSelectedBg: '#f5f6f8',
      colorBorder: '#E5E5E5',
      colorPrimary: '#999999',
      colorPrimaryHover: '#999999',
      controlOutline: 'none',
      colorIcon: '#272E34',
      colorIconHover: '#272E34',
      colorTextPlaceholder: '#cccccc',
      colorTextQuaternary: '#272E34',
      colorTextDisabled: '#666666',
      colorBgContainerDisabled: '#f5f6f8'
    },
    Popover: {
      colorBgElevated: '#272e34',
      colorText: '#ffffff',
      colorTextHeading: '#ffffff'
    },
    Radio: {
      colorPrimary: 'rgb(245, 34, 45)'
    },
    Switch: {
      colorPrimary: '#08BF6C',
      colorPrimaryHover: '#08BF6C'
    },
    Menu: {
      itemSelectedBg: 'none',
      itemBorderRadius: 0,
      itemBg: 'none',
      darkSubMenuItemBg: 'none',
      itemColor: '#020814',
      itemHoverColor: '#1664ff',
      itemHoverBg: 'none',
      itemSelectedColor: '#1664ff',
      itemActiveBg: 'none'
    },
    Table: {
      stickyScrollBarBg: 'none',
      borderColor: '#E5E5E5',
      headerBg: '#f5f6f8',
      headerColor: '#666666',
      colorText: '#272e34',
      cellPaddingBlock: 9,
      cellPaddingInline: 12
    },
    Input: {
      activeShadow: 'none',
      borderRadius: 4,
      colorTextPlaceholder: '#cccccc',
      colorBorder: '#E5E5E5',
      hoverBorderColor: '#999999',
      colorBgContainerDisabled: '#f5f6f8',
      activeBorderColor: '#999999',
      colorTextDisabled: '#666666',
    },
    InputNumber: {
      addonBg: '#f5f6f8',
    },
    Message: {
      contentPadding: 0,
      colorText: '#272e34'
    },
    Cascader: {
      optionSelectedBg: '#f5f6f8',
      controlItemBgHover: '#f5f6f8',
    }
  }
}
