import { makeAutoObservable } from 'mobx'
import { RootStore } from '../root-store'
import { PortData, DeviceTreeProps, PortForward, Hwversion, Swversion, Constant, defaultPort, sortList } from './type'
export class DeviceStore {
  rootStore: RootStore
  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }
  public devicePortTree:PortData[] = this.initPortList()
  public portsRunningLength = 0
  public swversionList: Swversion[] = [] // 软件包列表
  public hwversionList: Hwversion[] = [] // 固件包列表
  public constantList: Constant = {} // 常量列表
  public deviceModelOptions: {key:number, label:string}[] = [] // 设备版本号下拉框
  public deviceModelMap: {[id:string]:string} = {} // 设备版本号map
  public deviceRegStatusMap: {[id:string]:string} = {} // 注册状态map
  public regStatusOptions: {key:number, label:string}[] = [] // 注册状态下拉框
  public swversionMap: {[id:string]:string} = {} // 软件包列表
  public hwversionMap: {[id:string]:string} = {} // 固件包列表
  public busCollectionOptionsMap: {[id:string]:string} = {}
  public busCollectionOptionsOptions: {key:number, label:string}[] = []
 
  public busCollectionTriggerMap: {[id:string]:string} = {}
  public busCollectionTrigger: {key:number, label:string}[] = []
  public carModelTypeMap: {[id:string]:string} = {}
  public carModelType: {key:number, label:string}[] = []
  public carPowerModelMap: {[id:string]:string} = {}
  public carPowerModel: {key:number, label:string}[] = []
  public validFlagMap: {[id:string]:string} = {}
  public validFlag: {key:number, label:string}[] = []
  public devicePersonalizationMap: {[id:string]:string} = {}
  public devicePersonalization: {key:number, label:string}[] = []
  public testProjectStatusMap: {[id:string]:string} = {}
  public testProjectStatus: {key:number, label:string}[] = []
  public taskStatusMap: {[id:string]:string} = {}
  public taskStatus: {key:number, label:string}[] = []
  public isActiveMap: {[id:string]:string} = {}
  public isActive: {key:number, label:string}[] = []
  public signalAlarmTypeMap: {[id:string]:string} = {}
  public signalAlarmType: {key:number, label:string}[] = []
  public signalShowTypeMap: {[id:string]:string} = {}
  public signalShowType: {key:number, label:string}[] = []
  public usePredfMap: {[id:string]:string} = {}
  public usePredf: {key:number, label:string}[] = []
  public portTypeMap: {[id:string]:string} = {}
  public portType: {key:string, label:string}[] = []
  public upgradeObjMap: {[id:string]:string} = {}
  public upgradeObj: {key:string, label:string}[] = []

  updateDevicePortTree (value:DeviceTreeProps | null):void{
    // console.log(value, '--value')
    if (value === null) {
      this.portsRunningLength = 0
      this.devicePortTree = this.initPortList()
      return
    }
    const list = []
    try {
      if (Object.keys(value).length > 0){
        const maxLength = this.getPortChildrenMaxLengt
        Object.keys(value).forEach((item)=>{
          // console.log(PortForward[value[item]['name']])
          if (PortForward[value[item]['name']]){
            value[item]['name'] = PortForward[value[item]['name']]
            const childrenLength = value[item].children.length
            // const addLength = maxLength - childrenLength
            if (childrenLength < maxLength){
              for (let i = childrenLength;i < maxLength;i++){
                value[item].children.push({ index: i, status: 0 })
              }
            }
            
            list[sortList.indexOf(value[item]['name'])] = value[item]
          }
          
        })
        // console.log('list', list)
        this.portsRunningLength = list.reduce((accumulator, current) => {
          return accumulator + current.running
        }, 0)
        this.devicePortTree = list
      } else {
        this.devicePortTree = this.initPortList()
      }
    } catch (err){
      console.log('111', '走到重置')
      this.devicePortTree = this.initPortList()
    }
    
  
  }
  initPortList ():PortData[]{
    const list = []
    for (const i in defaultPort){
      const one = {}
      one['children'] = []
      one['all'] = defaultPort[i]
      one['name'] = i
      one['running'] = 0
      one['stop'] = 0
      one['idle'] = 0
      for (let k = 0;k < defaultPort[i];k++){
        one['children'].push({ index: k, status: 0 })
      }
      list.push(one)
    }
    
    const maxLength = 16
    list.forEach((item)=>{
      // value[item]['name'] = PortForward[value[item]['name']]
      const childrenLength = item.children.length
      if (childrenLength < maxLength){
        for (let i = childrenLength;i < maxLength;i++){
          item.children.push({ index: i, status: 0 })
        }

      }
    })
    return list
  }
  setPortsRunningLength (value: number): void {
    this.portsRunningLength = value
  }
  get getPortChildrenMaxLengt ():number{
    const maxArray = this.devicePortTree.map((item)=>{
      return item?.children && item?.children.length || 0
    })
    maxArray.sort((a, b)=>{
      return b - a
    })
    // console.log(maxArray)
    return maxArray[0]
  }

  setSwversionList (value: Swversion[]): void {
    this.swversionList = value
    if (value && value.length > 0){
      this.swversionMap = {}
      value.map((item) => {
        this.swversionMap[item.id] = item.name
      })
    }
  }
  get getSwversionList (): Swversion[] {
    return this.swversionList
  }
  setHwversionList (value: Hwversion[]): void {
    this.hwversionList = value
    if (value && value.length > 0){
      this.hwversionMap = {}
      value.map((item) => {
        this.hwversionMap[item.id] = item.name
      })
    }
  }
  get getHwversionList (): Hwversion[] {
    return this.hwversionList
  }
  setConstantList (value: Constant): void {
    this.constantList = value
    const { 
      model_number, 
      device_reg_status, 
      bus_collection_options, 
      bus_collection_trigger, 
      car_model_type, 
      car_power_model, 
      valid_flag, 
      device_personalization, 
      test_project_status, 
      task_status, 
      is_active,
      signal_alarm_type,
      signal_show_type,
      use_predef,
      port_type,
      upgrade_obj
    } = value
    if (model_number && Object.keys(model_number).length > 0) {
      this.deviceModelMap = model_number 
      this.deviceModelOptions = []
      Object.keys(model_number).map((key) => {
        const obj = {
          key: Number(key),
          label: model_number[key]
        }
        this.deviceModelOptions.push(obj)
      })
    }
    if (device_reg_status && Object.keys(device_reg_status).length > 0) {
      this.deviceRegStatusMap = device_reg_status 
      this.regStatusOptions = []
      Object.keys(device_reg_status).map((key) => {
        const obj = {
          key: Number(key),
          label: device_reg_status[key]
        }
        this.regStatusOptions.push(obj)
      })
    }
    if (bus_collection_options && Object.keys(bus_collection_options).length > 0) {
      this.busCollectionOptionsMap = bus_collection_options 
      this.busCollectionOptionsOptions = []
      Object.keys(bus_collection_options).map((key) => {
        const obj = {
          key: Number(key),
          label: bus_collection_options[key]
        }
        this.busCollectionOptionsOptions.push(obj)
      })
      // console.log('0624', this.busCollectionOptionsOptions)
    }
    if (bus_collection_trigger && Object.keys(bus_collection_trigger).length > 0) {
      this.busCollectionTriggerMap = bus_collection_trigger 
      this.busCollectionTrigger = []
      Object.keys(bus_collection_trigger).map((key) => {
        const obj = {
          key: Number(key),
          label: bus_collection_trigger[key]
        }
        this.busCollectionTrigger.push(obj)
      })
    }
    if (car_model_type && Object.keys(car_model_type).length > 0) {
      this.carModelTypeMap = car_model_type 
      this.carModelType = []
      Object.keys(car_model_type).map((key) => {
        const obj = {
          key: Number(key),
          label: car_model_type[key]
        }
        this.carModelType.push(obj)
      })
    }
    if (car_power_model && Object.keys(car_power_model).length > 0) {
      this.carPowerModelMap = car_power_model 
      this.carPowerModel = []
      Object.keys(car_power_model).map((key) => {
        const obj = {
          key: Number(key),
          label: car_power_model[key]
        }
        this.carPowerModel.push(obj)
      })
    }
    if (valid_flag && Object.keys(valid_flag).length > 0) {
      this.validFlagMap = valid_flag 
      this.validFlag = []
      Object.keys(valid_flag).map((key) => {
        const obj = {
          key: Number(key),
          label: valid_flag[key]
        }
        this.validFlag.push(obj)
      })
    }
    if (device_personalization && Object.keys(device_personalization).length > 0) {
      this.devicePersonalizationMap = device_personalization 
      this.devicePersonalization = []
      Object.keys(device_personalization).map((key) => {
        const obj = {
          key: Number(key),
          label: device_personalization[key]
        }
        this.devicePersonalization.push(obj)
      })
    }
    if (test_project_status && Object.keys(test_project_status).length > 0) {
      this.testProjectStatusMap = test_project_status 
      this.testProjectStatus = []
      Object.keys(test_project_status).map((key) => {
        const obj = {
          key: Number(key),
          label: test_project_status[key]
        }
        this.testProjectStatus.push(obj)
      })
    }
    if (task_status && Object.keys(task_status).length > 0) {
      this.taskStatusMap = task_status 
      this.taskStatus = []
      Object.keys(task_status).map((key) => {
        const obj = {
          key: Number(key),
          label: task_status[key]
        }
        this.taskStatus.push(obj)
      })
    }
    if (is_active && Object.keys(is_active).length > 0) {
      this.isActiveMap = is_active 
      this.isActive = []
      Object.keys(is_active).map((key) => {
        const obj = {
          key: Number(key),
          label: is_active[key]
        }
        this.isActive.push(obj)
      })
    }
    if (signal_alarm_type && Object.keys(signal_alarm_type).length > 0) {
      this.signalAlarmTypeMap = signal_alarm_type 
      this.signalAlarmType = []
      Object.keys(signal_alarm_type).map((key) => {
        const obj = {
          key: Number(key),
          label: signal_alarm_type[key]
        }
        this.signalAlarmType.push(obj)
      })
    }
    if (signal_show_type && Object.keys(signal_show_type).length > 0) {
      this.signalShowTypeMap = signal_show_type 
      this.signalShowType = []
      Object.keys(signal_show_type).map((key) => {
        const obj = {
          key: Number(key),
          label: signal_show_type[key]
        }
        this.signalShowType.push(obj)
      })
    }
    if (use_predef && Object.keys(use_predef).length > 0) {
      this.usePredfMap = use_predef 
      this.usePredf = []
      Object.keys(use_predef).map((key) => {
        const obj = {
          key: Number(key),
          label: use_predef[key]
        }
        this.usePredf.push(obj)
      })
    }
    if (port_type && Object.keys(port_type).length > 0) {
      this.portTypeMap = port_type 
      this.portType = []
      Object.keys(port_type).map((key) => {
        const obj = {
          key: key,
          label: port_type[key]
        }
        this.portType.push(obj)
      })
    }
    if (upgrade_obj && Object.keys(upgrade_obj).length > 0) {
      this.upgradeObjMap = upgrade_obj 
      this.upgradeObj = []
      Object.keys(upgrade_obj).map((key) => {
        const obj = {
          key: key,
          label: upgrade_obj[key]
        }
        this.upgradeObj.push(obj)
      })
    }
  }
  get getConstantList (): Constant {
    return this.constantList
  }
}
