

export type PortData = {
        all: number
        idle: number
        name: string
        running: number
        stop: number
        children: { index: number; status: number} []
    }

// 前后名字匹配
export enum PortForward{
    'can' = 'CAN',
    'lin' = 'LIN',
    'uart' = 'Serial',
    '100BaseT1' = 'Ethernet(100Base-T1)',
    '1000BaseT' = 'Ethernet(1000Base-T)',
    '1000BaseT1' = 'Ethernet(1000Base-T1)',
    '10GBaseT1'= 'Ethernet(10GBase-T1)',
    'flexray'= 'FlexRay',
    'ethlog' = 'ETH-LOG',
    'rtsp'= 'Camera(RTSP)'
}
export const sortList = ['CAN', 'LIN', 'Serial', 'FlexRay', 'Ethernet(100Base-T1)', 'Ethernet(1000Base-T1)', 'Ethernet(1000Base-T)', 'Ethernet(10GBase-T1)', 'ETH-LOG', 'Camera(RTSP)']

export const defaultPort = {
  'CAN': 16,
  'LIN': 12,
  'Serial': 8,
  'FlexRay': 2, 
  'Ethernet(100Base-T1)': 16,
  'Ethernet(1000Base-T1)': 8,
  'Ethernet(1000Base-T)': 4,
  'Ethernet(10GBase-T1)': 2,
  'ETH-LOG': 0,
  'Camera(RTSP)': 0
}

export interface DeviceTreeProps {
    [PortForward.can] : PortData
    [PortForward.lin] : PortData
    '10GBaseT1' : PortData
    '100BaseT1' : PortData
    '1000BaseT' : PortData
    '1000BaseT1': PortData
    'flexray': PortData
    'rtsp': PortData
}
export type Hwversion = {
    create_time:string
    creator_id:number
    id:number
    name:string
    path:string
}
export type Swversion = {
    create_time:string
    creator_id:number
    id:number
    name:string
    path:string
}
export type Constant = {
    [key: string]: Record<string, string>
}
