export const leftMenuItems:MenuItems[] = [
    {
      menuPath: '/dashbord/data-board',
      breadCrumb : ['数据看板']
    },
    {
      menuPath: '/device-management/device-list',
      breadCrumb : ['数采设备管理', '设备列表']
    },
    {
      menuPath: '/device-management/device-configuration',
      breadCrumb : ['数采设备管理', '设备配置']
    },
    {
      menuPath: '/device-management/device-firmware-update',
      breadCrumb : ['数采设备管理', '设备更新']
    },
    {
      menuPath: '/test-project-management/testproject',
      breadCrumb : ['测试工程管理']
    },
    {
      menuPath: '/monitoring/global-monitoring',
      breadCrumb : ['实时监控', '全局监控']
    },
    {
      menuPath: '/monitoring/single-device-monitoring',
      breadCrumb : ['实时监控', '单设备监控']
    },
    {
      menuPath: '/testing-data-analysis/testing-check',
      breadCrumb : ['测试数据分析', '存储数据查看']
    },
    {
      menuPath: '/testing-data-analysis/testing-show',
      breadCrumb : ['测试数据分析', '测试数据展示']
    },
    {
      menuPath: '/database-management/database',
      breadCrumb : ['Database管理']
    },
    {
      menuPath: '/master-data-management/vehicle-model',
      breadCrumb : ['主数据管理', '车型管理']
    },
    {
      menuPath: '/master-data-management/vehicle',
      breadCrumb : ['主数据管理', '车辆管理']
    },
    {
      menuPath: '/master-data-management/tester',
      breadCrumb : ['主数据管理', '测试员管理']
    },
    {
      menuPath: '/firmware-library/firmware-library-list',
      breadCrumb : ['更新包资源库']
    },
    {
      menuPath: '/system-management/account-management',
      breadCrumb : ['系统管理', '账号管理']
    },
    {
      menuPath: '/system-management/role-management',
      breadCrumb : ['系统管理', '角色管理']
    },
    {
      menuPath: '/system-management/operation-log',
      breadCrumb : ['系统管理', '操作日志']
    },
    {
      menuPath: '/system-management/customer-tags',
      breadCrumb : ['系统管理', '客户标签']
    },
    {
      menuPath: '/system-management/announcements',
      breadCrumb : ['系统管理', '更新公告']
    },
    {
      menuPath: '/download',
      breadCrumb : ['下载管理']
    },
    {
      menuPath: '/edit-password',
      breadCrumb : ['修改密码']
    },
  ]

  export interface MenuItems {
    menuPath:string;
    breadCrumb:string[]
  }



  export interface CustomBreadcrumbProps{
    showAddDevice:boolean
    showEditDevice:boolean
    showDeviceInfo: boolean
    showAddTask:boolean
    showEditTask:boolean
    showModifyConfig:boolean
    showModifyHistory:boolean
    showAccountUser: boolean
    showEditAccountUser: boolean
    showFirmwarelibrary: boolean
    showEditFirmwarelibrary: boolean
    showVehicleModel: boolean
    showVehicle: boolean
    showEditVehicle: boolean
    showRole: boolean
    showEditRole: boolean
    showRoleDetail: boolean
    showTester: boolean
    showEditTester: boolean
    showDatabase: boolean
    showEditDatabase: boolean
    showCustomerTags: boolean
    showEditCustomerTags: boolean
    showAddTestProject: boolean
    showEditTestProject: boolean
    showTestProjectDetail: boolean
    showFileDetail: boolean
    showAddAnnounce: boolean
    showEditAnnounce: boolean
  }
  export interface IAlarmRules {
    type?: string;
    topValue?: number; 
    middleValue?: number;
    lowValue?: number
  }